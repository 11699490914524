.DeployNetlify-button_deploy {
  border: none;
  border-radius: 70px;
  outline: 4px solid #123a4c;
  background-color: #123a4c;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: left;
  letter-spacing: 0.4px;
  line-height: 24px;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  padding: 0;
  min-width: 300px;
}

.DeployNetlify-button_deploy:hover {
  outline-color: hsl(189, 61%, 80%);
  background-color: hsl(189, 61%, 80%);
}

.DeployNetlify-button_cancel:hover {
  outline-color: #ff6584;
  background-color: #ff6584;
}

/* Add styles for the disabled state */
.DeployNetlify-button_deploy:disabled {
  background-color: #999; /* Change this to the desired background color for disabled state */
  cursor: not-allowed;
  opacity: 0.6; /* Optionally add opacity to indicate the disabled state */
}

.DeployNetlify-description {
  display: flex;
  border-radius: 70px;
  outline: 0;
  color: #ffff;
  padding-right: 8px;
  transition: all 0.3s ease-out 0.1s;
}

.DeployNetlify-button_cancel {
  border: none;
  border-radius: 70px;
  outline: 4px solid #123a4c;
  background-color: #123a4c;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: flex-start;
  letter-spacing: 0.4px;
  line-height: 24px;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  padding: 0;
  min-width: 300px;
}

.DeployNetlify-description {
  display: flex;
  border-radius: 70px;
  outline: 0;
  color: #ffff;
  padding-right: 8px;
  transition: all 0.3s ease-out 0.1s;
}

.status {
  background-color: #5856d6;
}

.new,
.accepted,
.uploaded,
.prepared,
.processed,
.published,
.ready {
  background-color: #28a745 !important; /* Green color */
}

.pending_review,
.enqueued,
.building,
.uploading,
.preparing,
.processing,
.retrying {
  background-color: #ffc107 !important; /* Yellow color */
}

.rejected,
.error {
  background-color: #dc3545 !important; /* Red color */
}

.loading,
.canceled {
  background-color: #6c757d !important; /* Gray color */
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.deploys-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  justify-content: flex-start;
}

.deploys-summary {
  cursor: pointer;
}

.deploys-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.c-pointer {
  cursor: pointer;
}
